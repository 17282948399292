// エラーメッセージ
export const ERROR_MESSAGE = {
  FAILED_MOUNT_ROOT: 'Mounted 先の要素が存在しません。',
  INPUT_REQUIRED: '必須項目です。',
  INPUT_HIRAGANA: 'ひらがなで入力してください。',
  INPUT_MIN_VALUE_PHONENUMBER: '10文字以上で入力してください。',
  INPUT_MAX_VALUE_PHONENUMBER: '12文字以下で入力してください。',
  INPUT_EMAIL: 'メールアドレスの形式で入力してください。',
  FAILED_SEND_EMAIL: 'メール送信に失敗しました',
};

// 成功メッセージ
export const SUCCESS_MESSAGE = {
  EMAIL_SEND: 'メールを送信しました',
};

// 画像パス
export const IMAGE = {
  RECRUIT:
    'https://lh3.googleusercontent.com/pw/ADCreHcgXh3_YM7ergq1ayt-yV-BCUiyo3dLA9iFgKP2C8Neq4qPHxMFbjrA4ZOsipGB1T1lTCwLOXDqQy55d0Miv51qCPjx3o3EeS1v05ModCvShx5B_vYCVQP_qdWpSpsdU346x-TneRi0UotcQTE0GHAF=w1280-h1280-s-no-gm?authuser=0',
  INSTAGRAM_BANNER:
    'https://lh3.googleusercontent.com/pw/ADCreHfYoMASeN3rRMd5XSgqBL9wjWyMAbudVwS_f2pIpBRUlxhalptsgSyMmherTw310NtAy-yM6DWRqoQ615zNeBdR5au273GGfwOko3bCQiK-R4_NpKFLRWlZSX0GYP6csLykrusiO8wQQH6w7FAnsJgW=w680-h320-s-no?authuser=0',
  LOGO_LARGE_WHITE:
    'https://lh3.googleusercontent.com/pw/ADCreHcdBLqEiVJ5iA_XOUd1jihvWCBAGYizVmyQDmdz5bDeVL3bXMzozuzG8ncLIaDjDPnfTgaiVaOD6uau4She_KUqkQaKLsoccKeUcZ5q-3DuLznK9oQl6Aqe_8uPYNZifGAx5UNfWMgcBiKTIZE9tNpU=w988-h395-s-no?authuser=0',
  SHOP_FRONT:
    'https://lh3.googleusercontent.com/pw/ADCreHeQEs97GpEBw2Qa3-f53LEcRsYySkvmK2sDxhoL_H_s0NhGRZ4nGru_1v_OYTOMHqDKo5oEG2Qp_Ig-2i6oqDZyWJVB0idImDIYIfQPPCDAF3RzqZNSgVyGG6y-oA4t5i2r9oHzkuqSY6HXKLsPRSO9=w1280-h1280-s-no?authuser=0',
  TEL: 'https://lh3.googleusercontent.com/pw/ADCreHdaqHnIcXX7orMIojJIkpZOi3ucPdhsXzlMDWymyPkaloK_jLkiIqJGyCkztTaUS-dBpQVQTCTddAwwc5PSUxgUtJLZfV9KWd2KZt5G5w4vU7Cr7pVDBPX2lOBDK9G0Y-vaCKW4wYmVlektUeBAAbfF=w421-h103-s-no?authuser=0',
  SLIDER_IMAGES: [
    'https://lh3.googleusercontent.com/pw/ADCreHdb3BHjsTiH-fUc8bOH8UF_ltoh29cJmt5LURu0cq0a39Fsiu_8ZVG6e8vfflVHmEWnQeEdCyH7GIflgt8HAhIeJ9q3L4Fev0r2Rqp3_9pgpOMrObyfNyda9YjuSlLv7_rZyGAKNavMFmfI7LUrG_WK=w1280-h1280-s-no-gm?authuser=0',
    'https://lh3.googleusercontent.com/pw/ADCreHciPCfiIDR2CnlAg55z4WeTpOekLmAFPWXq-I7BYVJSMBu4lgAOC7EDO6TU5hX9LhM7rYE9emR10yIzElgXWR7NRG-NJzIeMOgfM3Lyu6cZyOPARLMBfsJM8s9G90_zmgPjU0zDWn0YHQfz4T8-gBYp=w1280-h1280-s-no-gm?authuser=0',
    'https://lh3.googleusercontent.com/pw/ADCreHeywtlpnuP4MmQ_dYT9acjxxBfGQ4wCpvDxT7KX_yt1ncTOJxW1Rxi4IoCVtzfFLdeCjFlA53CUCEHR7ClmS6knL5NRSqmETOv_czAgx13vaAuPb84HBlOYP4P9yeABkjjFVVr23ZWPws_BRYykkRa_=w1280-h1280-s-no-gm?authuser=0',
    'https://lh3.googleusercontent.com/pw/ADCreHdFDlBEY98ADjmtDG-Q6Jc55g5BIOOmwsNG1T2TWcL1JhyOT4m0BdOTL7ECq4TSp_f8GCH8f__KRIu_RaEnwL2JLHMRYjSsCs8PEUOaAlo0OjkCreOEGxjNcriYqgedUEGMoloT3hC-sGET1z5UXiGk=w1280-h1280-s-no-gm?authuser=0',
  ],
  NO_IMAGE:
    'https://lh3.googleusercontent.com/pw/ADCreHcRI-BUmTeiQELCwoxufOLB7hRat8NzmsN-YszIjyDxg_mF1AyaRXqgZCarnGsxzse13X84gd39rmRfYuQeznzBIGGNRIqoK9ydJ_lYVOMEtT7EwNa3k9Adwe5IChvh77HewygYeW2Bv9qc6yMIoMp0=w170-h170-s-no?authuser=0',
};

// リンクパス
export const LINK = {
  INSTAGRAM: 'https://www.instagram.com/craftbeerfreela/',
  GOOGLE_MAP:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3246.4757840154975!2d139.44678957621292!3d35.54195003761174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f9ae92a75053%3A0xd50299cb49d2172d!2zQ3JhZnRiZWVyJktpdGNoZW4gZnJlZS5sYSDjgq_jg6njg5Xjg4jjg5Pjg7zjg6vjgqLjg7Pjg4njgq3jg4Pjg4Hjg7Mg44OV44Oq44O844Op!5e0!3m2!1sen!2sjp!4v1695875295559!5m2!1sen!2sjp',
  PRICACY_POLICY:
    'https://drive.google.com/file/d/1ybI1AaG3g2vllcFc9ZVszM3xguzvXnlD/view?usp=sharing',
};

// 重なり順
export const Z_INDEX = {
  HEADER: 3,
  HEADER_MENU: 2,
  MODAL: 4,
};

// カラー
export const COLOR = {
  HEADER_BG: '#fff',
  MENU_BG: 'rgba(34, 34, 34, 0.9)',
  MENU_TEXT: '#fff',
  BUTTON_TEXT: '#000',
  BUTTON_BG: '#fff',
  SUBMIT_BUTTON_TEXT: '#fff',
  SUBMIT_BUTTON_BG: '#000',
  SUBMIT_BUTTON_DISABLED_BG: '#d1d1d1',
  BUTTON_BORDER: '#000',
  BORDER: '#000',
  ERROR_TEXT: '#f00',
  SUCCESS_BG: '#b9ffe5',
  SUCCESS_BORDER: '#3f9877',
  FAILED_BG: '#ffb9b9',
  FAILED_BORDER: '#983f3f',
  FORM_LABEL_BORDER: '#000',
  FORM_INPUT_BORDER: '#000',
  FORM_INPUT_BORDER_ERROR: '#f00',
  FORM_TEXT: '#000',
  PRICE_TEXT: '#f00',
  MODAL_BG: '#fff',
};

// フォント名
export const FONT_FAMILY = {
  MENU: 'Elsie Swash Caps',
  HEADER_LARGE: 'Elsie Swash Caps',
  HEADER_MIDDLE: 'Elsie Swash Caps',
  HEADER_SMALL: 'Elsie Swash Caps',
};

// EmailJS
export const EMAILJS = {
  SERVICE_ID: 'service_a18clgq',
  PUBLIC_KEY: 'zNBOLqTYZuWQR1JbH',
  TEMPLATE_ID: {
    RECRUIT: 'template_ppw4e9m',
    OTHER: 'template_66mnvdq',
  },
};
